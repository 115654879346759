import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { AppConfigService, IAppConfigValue, IAppDropdownConfigValue } from '../services/AppConfigService';
import { BackendServiceUrl } from '../services/BaseService';

export interface IAppConfigResult<T> {
    Data?: T;
    Success: boolean;
    Message?: string;
}

export const useAppConfig = () => {
    const { instance, inProgress } = useMsal();
    const appConfigService = new AppConfigService(BackendServiceUrl);

    const getConfigSingleValue = async (key: string, user: string): Promise<IAppConfigResult<IAppConfigValue>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await appConfigService.getConfigSingleValueAsync(key, accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting config value ${key}. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getConfigMultipleValues = async (key: string, user: string): Promise<IAppConfigResult<IAppConfigValue[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const values = await appConfigService.getConfigMultipleValues(key, accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: values, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting config values ${key}. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };
    
    const getConfigDropdownValues = async (user: string): Promise<IAppConfigResult<IAppDropdownConfigValue[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const values: IAppDropdownConfigValue[] = await appConfigService.getConfigDropdownValues(accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: values, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting dropdown config values. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    return {
        getConfigSingleValue,
        getConfigMultipleValues,
        getConfigDropdownValues
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}
